import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { ApiResponse } from '@utils/models/ApiResponse';
import { ConfigService } from '@utils/interface/service.interface';

import { DateUtil } from '@utils/classes/DateUtil';
import { GenericService } from '@utils/classes/GenericService';
import { NgSelectOption } from '@utils/interface/ngselect.interfaces';
import { ScaContratoEntidad } from '~models/carga/interface/ScaContratoEntidad';
import { HttpClient } from '@angular/common/http';
import { DateFormat } from '~models/Util';

interface ContratoEntidadService {
	findAll(): Observable<ScaContratoEntidad[]>;
	findAllByIdEntidadAndEstadoNgSelect(idEntidad: number, estado: boolean): Observable<ScaContratoEntidad[]>;
	getSelectList(idEntidad: number, saveInMemory: boolean): Observable<NgSelectOption<ScaContratoEntidad>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class ScaContratoEntidadService extends GenericService<ScaContratoEntidad, number> implements ContratoEntidadService {
	private readonly _url = `${environment.HOST_CARGA}/scacontratoentidades`;
	private readonly dateUtil = new DateUtil();
	private _dataSelect$?: Observable<NgSelectOption<ScaContratoEntidad>[]>;

	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}
	// insert(entity: ScaContratoEntidad): Observable<ScaContratoEntidad> {
	// 	return this._http
	// 		.post<ApiResponse<ScaContratoEntidad>>(`${this._url}/`, entity)
	// 		.pipe(map((res) => res.data));
	// }

	findAll(): Observable<ScaContratoEntidad[]> {
		return this._http.get<ApiResponse<ScaContratoEntidad[]>>(`${this._url}/model-se-ro/findAll`).pipe(map((res) => res.data));
	}

	findAllByIdEntidadAndEstadoNgSelect(idEntidad: number, estado = true): Observable<ScaContratoEntidad[]> {
		return this._http.get<ApiResponse<ScaContratoEntidad[]>>(`${this._url}/model-se-ro/findAllByIdEntidadAndEstadoNgSelect/${idEntidad}/${String(estado)}`).pipe(map((res) => res.data));
	}

	getSelectList(idEntidad: number, saveInMemory = true): Observable<NgSelectOption<ScaContratoEntidad>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllByIdEntidadAndEstadoNgSelect(idEntidad, true).pipe(
				map((data) => this._filter(data)),
				map((data) =>
					data.map((scaContratoEntidad) => ({
						...scaContratoEntidad,
						value: scaContratoEntidad.idContratoEntidad,
						label: `Nro.: ${scaContratoEntidad?.nroContrato} / Vcto.: ${this.dateUtil.formatDate(scaContratoEntidad?.fchFin ?? '', DateFormat.DATE)}`,
					}))
				),
				shareReplay(1)
			);
		}

		return this._dataSelect$;
	}

	// Para que un contrato sea considerado vigente, la *Fecha actual* debe encontrarse en el rango. **Ser mayor o igual a la fecha de inicio y menor o igual a la fecha de fin.**
	private _filter(data: ScaContratoEntidad[]): ScaContratoEntidad[] {
		return data.filter((d) => {
			const { fchInicio, fchFin } = d;
			const currentDate = new Date().getTime();

			if (fchInicio && fchFin) return currentDate >= new Date(fchInicio).getTime() && currentDate <= new Date(fchFin).getTime();

			return true;
		});
	}

	getSelect(e: ScaContratoEntidad | null | undefined): NgSelectOption<ScaContratoEntidad> | null {
		return e
			? {
					...e,
					value: e.idContratoEntidad,
					label: `Nro.: ${e.nroContrato} / Vcto.: ${e.fchContrato && this.dateUtil.formatDate(e.fchContrato, DateFormat.DATE)}`,
			  }
			: null;
	}
}
